// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

const clientId = 'eu2yyubcxn';
const clientSecret = '3xDUaWwW84qB9QPXX3Hy';

function access_to_api() {
  // 多重送信を防ぐため通信完了までボタンをdisableにする
  const button = $(this);
  button.attr('disabled', true);
  // 各フィールドから値を取得してJSONデータを作成
  const data = {
    client_id: clientId,
    client_secret: clientSecret,
    userkey: localStorage.getItem('customerconcierge-token'),
    access_link: 'https://ticket.at-yokohama.net/mypage/',
  };

  // 通信実行
  $.ajax({
    type: 'post', // method = 'POST'
    url: 'https://ticket.at-yokohama.net/api/check', // POST送信先のURL
    data: JSON.stringify(data), // JSONデータ本体
    contentType: 'application/json', // リクエストの Content-Type
    dataType: 'json', // レスポンスをJSONとしてパースする

    success: function(json_data) { // 200 OK時
      // JSON Arrayの先頭が成功フラグ、失敗の場合2番目がエラーメッセージ
      if (!json_data[0]) { // サーバが失敗を返した場合
        alert('Transaction error. ' + json_data[1]);
        return;
      }
      const access_link = json_data['access_link'];
      const Token = json_data['Token'];
      const result = json_data['result'];
      if (result == 'OK'){
        // 成功時処理
        localStorage.setItem('has_ticket_api_accessed', 'true'); // アクセス有無のフラグをローカルストレージに保存
        window.location.href = access_link + Token;
      } else {
        //失敗
        alert('失敗しました');
        return;
      }
    },

    error: function() { // HTTPエラー時
      alert('Server Error. Please try again later.');
    },

    complete: function() { // 成功・失敗に関わらず通信が終了した際の処理
      button.attr('disabled', false); // ボタンを再び enableにする
    }
  });
}

$('#login').click(function() {
  access_to_api();
});

$('#purchase-history').click(function() {
  access_to_api();
});

// 一度でもログインをした人はログインボタンを非表示にするための処理
$(document).ready(function() {
  const hasAccessed = localStorage.getItem('has_ticket_api_accessed');

  if (hasAccessed === 'true') {
    $('.my-page__login-container').addClass('d-none');
  }
});
