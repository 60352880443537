import PartialImg from './_PartialImg';
import Area from './_Area';
import CardSwiper from './_CardSwiper';
import ImagemapResizer from './_ImagemapResizer';

class TopMapYokohama {
  constructor(
    { activeAreaId, mapSelectScrollTarget } = {
      activeAreaId: 'q_areas_id_in_1',
      mapSelectScrollTarget: document.querySelector('.area-map-scroll-target'),
    }
  ) {
    // Setting
    //

    this.state = {
      bp: 1012,
      activeAreaId,
      isSp: false,
    };

    // Elms
    //

    this.appElm = document.querySelector('[data-role="top-map-yokohama"]');
    this.imagemap = this.appElm.querySelector('[data-role="top-map-imagemap"]');
    this.baseMapImg = this.appElm.querySelector('[data-role="top-map-base-map-img"]');
    this.partialImgs = [];
    this.appElm.querySelectorAll('[data-role="top-map-partial-map-img"]').forEach((slice) => {
      this.partialImgs.push(
        new PartialImg({
          elm: slice,
          app: this,
        })
      );
    });
    this.areas = [];
    this.appElm.querySelectorAll('[data-role="top-map-imagemap-area"]').forEach((btn) => {
      this.areas.push(
        new Area({
          elm: btn,
          app: this,
          scrollTarget: mapSelectScrollTarget,
        })
      );
    });
    this.cardSwiper = new CardSwiper({
      app: this,
      elm: this.appElm.querySelector("[data-role='top-map-card-swiper']"),
      prevElm: this.appElm.querySelector("[data-role='top-map-card-swiper-prev']"),
      nextElm: this.appElm.querySelector("[data-role='top-map-card-swiper-next']"),
    });

    // util
    //

    this.imagemapResizer = new ImagemapResizer({ app: this, imagemap: this.imagemap, baseMapImg: this.baseMapImg });

    // events
    //

    window.addEventListener('resize', () => {
      this.state.isSp = this.isSp();
      this.render();
    });

    // init
    //

    let cnt = 0;

    this.partialImgs.forEach((img) => {
      img.elm.addEventListener('load', () => {
        cnt++;
        if (cnt === this.partialImgs.length) {
          this.render();
        }
      });
    });
  }

  isSp() {
    return window.innerWidth <= this.state.bp;
  }

  render() {
    this.areas.forEach((area) => {
      area.render();
    });
    this.partialImgs.forEach((img) => {
      img.render();
    });
    this.cardSwiper.render();

    // getBoundingClientRectが正しく要素の高さを取得するためやや遅延させる
    setTimeout(() => {
      this.imagemapResizer.render();
    }, 300);
  }
}

export default TopMapYokohama;
