import Swiper from 'swiper/bundle';

const swiperMv = document.querySelectorAll('.swiper-mv');
if (swiperMv) {
  swiperMv.forEach((elm) => {
    new Swiper(elm, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: true,
      pagination: {
        el: elm.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: elm.querySelector('.swiper-button-next'),
        prevEl: elm.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        768: {
          spaceBetween: 40,
          slidesPerView: 1.5,
        },
      },
    });
  });
}

const swiperSingle = document.querySelectorAll('.swiper-single');
if (swiperSingle) {
  swiperSingle.forEach((elm) => {
    new Swiper(elm, {
      loop: true,
      slidesPerView: 1,
      pagination: {
        el: elm.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: elm.querySelector('.swiper-button-next'),
        prevEl: elm.querySelector('.swiper-button-prev'),
      },
    });
  });
}

const swiperTriple = document.querySelectorAll('.swiper-triple');
if (swiperTriple) {
  swiperTriple.forEach((elm) => {
    new Swiper(elm, {
      loop: true,
      slidesPerView: 1.5,
      spaceBetween: 10,
      centeredSlides: true,
      pagination: {
        el: elm.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: elm.querySelector('.swiper-button-next'),
        prevEl: elm.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
      },
    });
  });
}

const swiperRelated = document.querySelectorAll('.swiper-related');
if (swiperRelated) {
  swiperRelated.forEach((elm) => {
    new Swiper(elm, {
      loop: true,
      slidesPerView: 1.2,
      spaceBetween: 10,
      navigation: {
        nextEl: elm.querySelector('.swiper-button-next'),
        prevEl: elm.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        768: {
          spaceBetween: 40,
        },
      },
    });
  });
}

const swiperAnchorlinks = document.querySelectorAll('.swiper-anchorlinks');
const slideCount = document.querySelectorAll('.swiper-slide').length;
if (swiperAnchorlinks) {
  if (slideCount > 4) {
    swiperAnchorlinks.forEach((elm) => {
      new Swiper(elm, {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 10,
        centeredSlides: true,
        navigation: {
          nextEl: elm.querySelector('.swiper-button-next'),
          prevEl: elm.querySelector('.swiper-button-prev'),
          disabledClass: 'swiper-button-hidden',
        },
        breakpoints: {
          992: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
        },
      });
    });
  } else {
    swiperAnchorlinks.forEach((elm) => {
      new Swiper(elm, {
        loop: false,
        slidesPerView: 2.5,
        spaceBetween: 20,
        navigation: {
          nextEl: elm.querySelector('.swiper-button-next'),
          prevEl: elm.querySelector('.swiper-button-prev'),
          disabledClass: 'swiper-button-hidden',
        },
        breakpoints: {
          992: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        },
      });
    });
  }
}
