// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  // 現在地取得成功時のみサーバに処理を送信する
  function successGetPosition(position) {
    const url = $('#sort_distance').attr('href');
    $('#sort_distance').attr('href', `${url}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`);
    window.location.href = `${url}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`;
  }

  function failGetPosition(error) {
    // eslint-disable-next-line default-case
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // eslint-disable-next-line no-alert
        alert('位置情報の取得が許可されませんでした');
        break;
      case error.POSITION_UNAVAILABLE:
        // eslint-disable-next-line no-alert
        alert('電波状況などで位置情報が取得できませんでした');
        break;
      case error.TIMEOUT:
        // eslint-disable-next-line no-alert
        alert('位置情報の取得にてタイムアウトが発生しました');
        break;
      case error.UNKNOWN_ERROR:
        // eslint-disable-next-line no-alert
        alert('原因不明のエラーが発生しました');
        break;
    }
  }

  // 現在地に近いボタン押下時、現在地を取得する
  if ($('#sort_distance').length) {
    $('#sort_distance').click(() => {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successGetPosition, failGetPosition, options);
      } else {
        // eslint-disable-next-line no-alert
        alert('ご使用中のブラウザは現在地検索に対応されておりません');
      }
      return false;
    });
  }

  if ($('#spot_search').length) {
    $('input.category_id').on('click', function(e) {
      const parentElement = $(e.target).closest('div');

      // 親カテゴリーに対応する子カテゴリーのチェックボックスを全て選択または解除
      parentElement.find('input[name="q[child_categories_id_in][]"]').prop('checked', e.target.checked);
    });

    $('input[name="q[child_categories_id_in][]"]').on('change', function() {
      const categoryId = $(this).data('category-id');
      const parentCheckbox = $(`#category_id_${categoryId}`);

      // 紐づく子カテゴリのチェックボックスのいずれかが非アクティブなら、親カテゴリも非アクティブに
      if (!$(this).prop('checked')) {
        parentCheckbox.prop('checked', false);
      } else {
        // すべての子カテゴリがアクティブなら、親カテゴリもアクティブに
        const allChecked = $(`input[data-category-id="${categoryId}"]:checked`).length ===
          $(`input[data-category-id="${categoryId}"]`).length;
        parentCheckbox.prop('checked', allChecked);
      }
    });
  }
});
