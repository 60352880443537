// AI協調フィルタリング
const aiRecommend = document.querySelector('#top-ai-recommend');
if (aiRecommend) {
  $.ajax({
    url: '/recommends',
    data: {
      recommend_type: 'user',
      data_id: 'top-ai-recommend',
      data_type: 'top',
    },
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    type: 'GET',
    timeout: 5000,
  });
}

// グロナビ固定
if (document.querySelector('.Sec-mv') !== null) {
  let gnavFixed = () => {
    const globalHeader = document.querySelector('.global-header');
    const globalHeaderShowPoint = document.querySelector('.Sec-mv').clientHeight;
    const scroll = document.documentElement.scrollTop;
    if (scroll >= globalHeaderShowPoint) {
      globalHeader.classList.add('is-show');
    } else {
      globalHeader.classList.remove('is-show');
    }
  };
  window.addEventListener('load', gnavFixed);
  window.addEventListener('scroll', gnavFixed);
  window.addEventListener('resize', gnavFixed);
}
