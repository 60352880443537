import ArchiveFilter from './components/ArchiveFilter/ArchiveFilter';
import KanagawaArchiveFilter from './components/KanagawaArchiveFilter/ArchiveFilter';
import TopMv from './components/TopMv';
import TopMapYokohama from './components/TopMapYokohama/TopMap';
import TopMapKanagawa from './components/TopMapKanagawa/TopMap';

// ArchiveFilter
//
// アーカイブ一覧ページにおける絞り込みフォーム要素パーツ
if (document.querySelector('[data-role="archive-filter"]')) {
  if (document.querySelector('.archive-filter-area-map')) {
    // eslint-disable-next-line no-new
    new ArchiveFilter();
  } else {
    // eslint-disable-next-line no-new
    new ArchiveFilter({ disableAreaMapMode: true });
  }
  if (document.querySelector('.kanagawa-archive-filter-area-map')) {
    // eslint-disable-next-line no-new
    new KanagawaArchiveFilter();
  } else {
    // eslint-disable-next-line no-new
    new KanagawaArchiveFilter({ disableAreaMapMode: true });
  }
}

// TopMv
if (document.querySelector("[data-role='top-mv-swiper']")) {
  // eslint-disable-next-line no-new
  new TopMv();
}

// TopMap
if (document.querySelector('[data-role="top-map-yokohama"]')) {
  // eslint-disable-next-line no-new
  new TopMapYokohama({
    activeAreaId: 'q_areas_id_in_1',
    mapSelectScrollTarget: document.querySelector('.area-map-scroll-target'),
  });
}

if (document.querySelector('[data-role="top-map-kanagawa"]')) {
  // eslint-disable-next-line no-new
  new TopMapKanagawa({
    activeAreaId: 'q_kanagawa_areas_id_in_1',
    mapSelectScrollTarget: document.querySelector('.area-map-scroll-target'),
  });
}

document.addEventListener('DOMContentLoaded', () => {
  // 高さを更新する関数
  function handleResize(entries) {
    const height = entries[0].contentRect.height;
    document.documentElement.style.setProperty('--header-height', `${height}px`);
  }

  // ResizeObserverのインスタンスを作成
  const resizeObserver = new ResizeObserver(handleResize);

  // 監視する要素を取得
  const header = document.querySelector('.global-header');
  
  if (!header) return;

  // 要素を監視
  resizeObserver.observe(header);
});
