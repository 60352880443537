function switchAllCheckbox(targetName) {
  if (document.querySelector(`.checkbox_for_${targetName}_all_search`)) {
    document.querySelector(`.checkbox_for_${targetName}_all_search`).addEventListener('click', (e) => {
      // 全てをチェックされた場合、子要素を全てcheckする
      document.querySelectorAll(`.checkbox_for_${targetName}_id_search`).forEach((element) => {
        element.checked = e.target.checked;
      });
    });

    document.querySelectorAll(`.checkbox_for_${targetName}_id_search`).forEach((e) => {
      e.addEventListener('click', () => {
        const checkBoxes = document.querySelectorAll(`.checkbox_for_${targetName}_id_search`);
        if (document.querySelectorAll(`.checkbox_for_${targetName}_id_search:checked`).length == checkBoxes.length) {
          document.querySelector(`.checkbox_for_${targetName}_all_search`).checked = true;
        } else {
          document.querySelector(`.checkbox_for_${targetName}_all_search`).checked = false;
        }
      });
    });
  }
}

switchAllCheckbox('area', '');
switchAllCheckbox('kanagawa_area', '');
switchAllCheckbox('category', '');
switchAllCheckbox('child_category', '');
switchAllCheckbox('tag', '');

document.addEventListener('DOMContentLoaded', () => {
  const buttonElements = document.querySelectorAll('[data-role="archive-filter-selector"]');
  const popup = document.querySelector('[data-role="archive-filter-content-popup"]');

  const threshold = 8;

  // 要素が見つからない場合は処理を終了
  if (!popup || !buttonElements) return;

  // クリック時にポップアップの位置を計算して設定する関数
  const setPosition = (element) => {
    const formElement = document.querySelector('[data-role="archive-filter"]');
    if (!formElement) return;  // formElementが見つからない場合は処理を終了

    const formClientTop = formElement.getBoundingClientRect().top;
    const height = element.clientHeight;
    const top = element.getBoundingClientRect().top;

    const positionTop = top - formClientTop + height + threshold;

    popup.style.top = `${positionTop}px`;
  };

  // 各ボタンにイベントリスナーを設定
  buttonElements.forEach((element) => {
    element.addEventListener('click', () => {
      setPosition(element);
    });
  });
});
