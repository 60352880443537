// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  if ($('#item-event-recommend').length) {
    $.ajax({
      url: '/recommends',
      data: {
        recommend_type: 'item',
        data_id: 'item-event-recommend',
        data_type: 'event',
      },
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }

  if ($('#item-feature-recommend').length) {
    $.ajax({
      url: '/recommends',
      data: {
        recommend_type: 'item',
        data_id: 'item-feature-recommend',
        data_type: 'feature',
      },
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }

  if ($('#item-spot-recommend').length) {
    $.ajax({
      url: '/recommends',
      data: {
        recommend_type: 'item',
        data_id: 'item-spot-recommend',
        data_type: 'spot',
      },
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }

  if ($('#user-all-content-recommend').length) {
    $.ajax({
      url: '/recommends',
      data: {
        recommend_type: 'user',
        data_id: 'user-all-content-recommend',
        data_type: 'all',
      },
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }
});
