document.addEventListener('DOMContentLoaded', function () {
  const tabPanes = document.querySelectorAll('.tab-pane');
  const tabTypeItems = document.querySelectorAll('.tab-type_1__item');

  tabTypeItems.forEach(function (tabTypeItem) {
    tabTypeItem.addEventListener('click', function () {
      // 他の tab-type_1__item の関連する tab-sub__item の最後の要素に active を付与
      tabTypeItems.forEach(function (item) {
        if (!item.classList.contains('active')) {
          const targetPaneId = item.getAttribute('data-bs-target');
          const targetPane = document.querySelector(targetPaneId);
          const tabItems = targetPane.querySelectorAll('.tab-sub__item');

          if (tabItems.length > 0) {
            // すべての .tab-sub__item から active クラスを削除
            tabItems.forEach(function (tab) {
              tab.classList.remove('active');
            });

            // 最後の要素に active クラスを追加
            tabItems[tabItems.length - 1].classList.add('active');
          }
        }
      });
    });
  });

  tabPanes.forEach(function (pane) {
    const tabItems = pane.querySelectorAll('.tab-sub__item');

    tabItems.forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault(); // デフォルトのリンク動作を防ぐ

        // 同じ tab-pane 内のすべてのアイテムから active クラスを削除
        tabItems.forEach(function (tab) {
          tab.classList.remove('active');
        });

        // クリックされたアイテムに active クラスを追加
        item.classList.add('active');
      });
    });
  });
});
