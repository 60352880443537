const startBtns = document.querySelectorAll('button[data-id="start-button"]');
let startId = null;

const goalBtns = document.querySelectorAll('button[data-id="goal-button"]');
let goalId = null;

const submitBtn = document.querySelector('a.access__submit-button');

function embEkitanUrl(baseURL) {
    const url = baseURL + "?fromStation=" + startId + "&toStation=" + goalId;
    submitBtn.setAttribute('href', url);
}

function handleButtonClick(buttons, setIdCallback) {
    buttons.forEach(function(button) {
        button.addEventListener('click', function() {
            buttons.forEach(btn => btn.classList.remove('active'));
            setIdCallback(button.getAttribute('data-search-eid'));
            button.classList.add('active');
        });
    });
}

if (document.querySelector('.js-access-gmap-2point-link-create-ui')) {
    const EKITANURL = submitBtn.getAttribute('href');
    submitBtn.addEventListener('click', function(evt) {
        if (startId == null || goalId == null){
            evt.preventDefault();
            alert("出発地点と選択地点の両方を選択してください");
        }
    });

    handleButtonClick(startBtns, function(id) {
        startId = id;
        embEkitanUrl(EKITANURL);
    });

    handleButtonClick(goalBtns, function(id) {
        goalId = id;
        embEkitanUrl(EKITANURL);
    });
}
