const languageMenuOpener = document.querySelectorAll('.js-language-list-toggle');

languageMenuOpener.forEach((opener) => {
  opener.addEventListener('click', function () {
    // gnav-language-list の要素を取得
    const languageList = this.closest('.gnav-language').querySelector('.gnav-language-list');

    // .active クラスをトグルする
    languageList.classList.toggle('active');

    // 他のリストを閉じる（オプション：複数の言語メニューがある場合）
    document.querySelectorAll('.gnav-language-list').forEach((list) => {
      if (list !== languageList) {
        list.classList.remove('active');
      }
    });
  });
});

// 言語選択リンクがクリックされたとき、リストを閉じる
const changeLanguageBtns = document.querySelectorAll('[data-role="change-language"]');
changeLanguageBtns.forEach((changeLanguageBtn) => {
  changeLanguageBtn.addEventListener('click', () => {
    // 親の gnav-language-list から .active クラスを削除
    const languageList = changeLanguageBtn.closest('.gnav-language-list');
    languageList.classList.remove('active');
  });
});
